import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { useTimer } from "react-timer-hook";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Connect from "../components/Connect";

const Home = () => {
  const { t } = useTranslation();
  const basePath = "banner.";

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 2 * 24 * 60 * 60);

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("Timer expired"),
  });

  return (
    <>
      <NavBar />

      <section className="main">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="content">
                <h1>50000 BNB {t(`${basePath}title`)}</h1>
                <p>{t(`${basePath}desc`)}</p>

                <div className="presale-card-wrapper">
                  <div className="presale-card">
                    <div className="presale-card-header">
                      <h5>⚡{t(`${basePath}subtitle`)}</h5>
                    </div>
                    {/* <div className="presale-card-counter">
                      <div className="counter">
                        <div className="count-item">
                          <span className="count">{days}</span>
                          <span className="label">d</span>
                        </div>
                        <div className="count-item">
                          <span className="count">{hours}</span>
                          <span className="label">h</span>
                        </div>
                        <div className="count-item">
                          <span className="count">{minutes}</span>
                          <span className="label">m</span>
                        </div>
                        <div className="count-item">
                          <span className="count">{seconds}</span>
                          <span className="label">s</span>
                        </div>
                      </div>
                    </div> */}
                    <div className="presale-card-body">
                      <div className="info">
                        <h5>
                          {t(`${basePath}target`)} 50000 {t(`${basePath}token`)}
                          !
                        </h5>
                        <h5>15567 / 50000</h5>
                      </div>
                      <div className="mb-35">
                        <div className="progress">
                          <div
                            className="progress-done"
                            style={{ width: "30%" }}
                          >
                            <p>30%</p>
                          </div>
                        </div>
                      </div>
                      <Connect />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="scroll-slider-wrapper">
          <div className="scroll-slider-content">
            <Marquee>
              <div className="item">
                <img
                  src={require("../assets/img/icons/bnb.svg").default}
                  alt=""
                />
              </div>
              <div className="item">
                <h2>Binance Coin: 289</h2>
              </div>
              <div className="item">
                <img
                  src={require("../assets/img/icons/trx.svg").default}
                  alt=""
                />
              </div>
              <div className="item">
                <h2>{t(`${basePath}target`)}: 50000</h2>
              </div>
              <div className="item">
                <img
                  src={require("../assets/img/icons/bnb.svg").default}
                  alt=""
                />
              </div>
              <div className="item">
                <h2>Tron: 26631</h2>
              </div>
              <div className="item">
                <img
                  src={require("../assets/img/icons/trx.svg").default}
                  alt=""
                />
              </div>
            </Marquee>
          </div>
        </div>
      </section>

      <Footer />
      <ToastContainer />
    </>
  );
};

export default Home;
